<template>
    <div class="BoothAdvertisingTwo">
        <div class="headerStep">
            <div class="stepOne">
                <span class="num numActive">1</span>
                <span class="text">创建展位数据</span>
            </div>
            <img v-if="activeIndex == 1" class="arrows" src="./../../../assets/images/serviceData/xian.png" alt="">
            <img v-if="activeIndex > 1" class="arrows" src="./../../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="activeIndex > 1 ? 'num numActive' : 'num'">2</span>
                <span class="text">添加展位数据</span>
            </div>
            <img v-if="activeIndex < 4" class="arrows" src="./../../../assets/images/serviceData/xian.png" alt="">
            <img v-if="activeIndex > 3" class="arrows" src="./../../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="activeIndex > 3 ? 'num numActive' : 'num'">3</span>
                <span class="text">添加竞争数据</span>
            </div>
            <img v-if="activeIndex < 5" class="arrows" src="./../../../assets/images/serviceData/xian.png" alt="">
            <img v-if="activeIndex > 4" class="arrows" src="./../../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="activeIndex > 4 ? 'num numActive' : 'num'">4</span>
                <span class="text">创建成功</span>
            </div>
        </div>
<!--        第一步-->
        <el-form v-if="activeIndex === 1" style="margin-left: 40px; margin-top: 130px" label-width="100px" :model="formOne" ref="form" :rules="rules">
            <el-form-item label="创建名称" prop="name">
                <el-input style="width: 500px;" v-model="formOne.name"></el-input>
            </el-form-item>
            <el-form-item label="行业类别" prop="industry_id">
                <el-select v-model="formOne.industry_id" placeholder="请选择" style="width: 500px;">
                    <el-option
                            v-for="item in classifyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="点击量分值" prop="score">
              <el-input-number class="number-left" v-model="formOne.score" :min="0.5" :max="100" label="点击量分值" :controls="false" :precision="1" style="width: 500px"></el-input-number>
            </el-form-item>
            <el-form-item label="成交量分值" prop="deal_score">
              <el-input-number class="number-left" v-model="formOne.deal_score" :min="0.5" :max="100" label="点击量分值" :controls="false" :precision="1" style="width: 500px"></el-input-number>
            </el-form-item>
            <el-form-item label="转化率分值" prop="transform_score">
              <el-input-number class="number-left" v-model="formOne.transform_score" :min="0.5" :max="100" label="点击量分值" :controls="false" :precision="1" style="width: 500px"></el-input-number>
            </el-form-item>
            <el-form-item label="余额" prop="residual">
                <el-input v-model.number="formOne.residual" oninput="value=value.replace(/[^\d]/g,'')" style="width: 500px;"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="formOne.remake" type="textarea" :rows="4" style="width: 500px;"></el-input>
            </el-form-item>
            <el-form-item label="任务背景" prop="task_background">
                <el-input v-model="formOne.task_background" type="textarea" :rows="6" placeholder="1-1000个字" style="width: 500px" maxlength="1000" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="任务要求" prop="tack_demands">
                <el-input v-model="formOne.tack_demands" type="textarea" :rows="6" placeholder="1-1000个字" style="width: 500px" maxlength="1000" show-word-limit></el-input>
            </el-form-item>
        </el-form>

<!--        第二步-->
        <div class="BoothAdvertisingTwoHeader">
            <el-form :model="stepTwo" ref="stepTwo" class="step-two-form">
                <el-form-item label="使用官方数据" label-width="108px" label-position="left" class="office-title" v-if="activeIndex === 2">
                    <el-select v-model="SelectValue" placeholder="请选择" style="width: 300px;" @change="selectOfficial">
                        <el-option
                                v-for="item in officialOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用官方数据" label-width="108px" label-position="left" class="office-title" v-if="activeIndex === 3">
                    <el-select v-model="SelectValue" placeholder="请选择" style="width: 300px;" @change="selectOfficial2">
                        <el-option
                                v-for="item in officialOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用官方数据" label-width="108px" label-position="left" class="office-title" v-if="activeIndex === 4">
                    <el-select v-model="SelectValue" placeholder="请选择" style="width: 300px;" @change="selectOfficial3">
                        <el-option
                                v-for="item in officialOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div v-if="activeIndex === 2 || activeIndex === 3" :class="activeIndex===2?'form-title': 'form-title2'">
                    <i class="iconfont">&#xe63d;</i>时段设置
                </div>
<!--                时段设置-->
                <div class="timeSet" v-if="activeIndex===2">
                    <div class="time-title" style="width: 810px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix" v-for="(item,index) in stepTwo.time_interval" :key="index">
                        <div class="oneToSix-item1">
                            <span style="padding-left: 16px">{{index === 0 ? '0~6' : index === 1 ? '6~12' : index === 2 ? '12~18' : index === 3 ? '18~24' : ''}}</span>
                            <el-input class="item-input" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 180px;"></el-input>
                            <span><el-input-number :min="0.01" :max="100" :precision="2" :controls="false" class="item-input" v-model.number="item.click" style="margin-left: 86px"></el-input-number>%</span>
                            <span><el-input-number :min="0.01" :max="100" :precision="2" :controls="false" class="item-input" v-model.number="item.conversion" style="margin-left: 70px"></el-input-number>%</span>
                        </div>
                        <div class="oneToSix-item2">
                            <div>波动范围</div>
                            <div class="item2-input" style="margin-left: 14px">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.cuslower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number :controls="false" class="item-input" @change="watchDiffer(item.cuslower,item.cusupper)" :max="1000" :min="-98" v-model.number="item.cusupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                            <div class="item2-input" style="margin-left: 74px">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.clilower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number :controls="false" class="item-input" @change="watchDiffer(item.clilower,item.cliupper)" :max="1000" :min="-98" v-model.number="item.cliupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                            <div class="item2-input" style="margin-left: 74px">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.conlower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number :controls="false" class="item-input" @change="watchDiffer(item.conlower,item.conupper)" :max="1000" :min="-98" v-model.number="item.conupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                        </div>
                    </div>
                </div>

                <div class="timeSet2" v-if="activeIndex===3">
                    <div class="timeSet2-left">
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                    </div>
                    <div class="timeSet2-right">
                        <div class="timeSet2-rightItem" v-for="(item,index) in stepThree.timeInterval.data">
                            <span>{{item.name}}</span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" v-model.number="item.customers"></el-input></span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click"></el-input><span style="padding-top: 14px">%</span></span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion"></el-input><span style="padding-top: 14px">%</span></span>
                        </div>
                    </div>
                </div>

                <div v-if="activeIndex === 2 || activeIndex === 3" :class="activeIndex===2?'form-title': 'form-title2'" style="margin-top: 40px">
                    <i class="iconfont">&#xe7dd;</i>地区设置
                </div>
<!--                地区设置-->
                <div class="regionSet" v-if="activeIndex===2">
                    <div class="time-title" style="width: 830px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix" v-for="(item,index) in stepTwo.region" :key="index">
                        <div class="oneToSix-item1">
                            <span style="padding-left: 16px;">{{index===0?'华北地区':index===1?'东北地区':index===2?'华东地区':index===3?'华中地区':index===4?'华南地区':index===5?'西南地区':'西北地区'}}</span>
                            <el-input class="item-input" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 180px;"></el-input>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click" style="margin-left: 86px;width: 180px;"></el-input>%</span>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion" style="margin-left: 70px;width: 180px;"></el-input>%</span>
                        </div>
                        <div class="oneToSix-item2">
                            <div style="padding-left: 16px">波动范围</div>
                            <div class="item2-input" style="margin-left: 14px;">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.cuslower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number @change="watchDiffer(item.cuslower,item.cusupper)" :controls="false" class="item-input" :max="1000" :min="-98" v-model.number="item.cusupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                            <div class="item2-input" style="margin-left: 74px">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.clilower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number @change="watchDiffer(item.clilower,item.cliupper)" :controls="false" class="item-input" :max="1000" :min="-98" v-model.number="item.cliupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                            <div class="item2-input" style="margin-left: 74px">
                                <el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.conlower" style="margin-right: 4px;width: 70px;"></el-input-number>% ~
                                <el-input-number @change="watchDiffer(item.conlower,item.conupper)" :controls="false" class="item-input" :max="1000" :min="-98" v-model.number="item.conupper" style="margin-left: 6px;width: 70px;"></el-input-number>%
                            </div>
                        </div>
                    </div>
                </div>

                <div class="timeSet2" v-if="activeIndex===3">
                    <div class="timeSet2-left">
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                        <span>潜在客户数</span>
                        <span>点击率</span>
                        <span>转化率</span>
                    </div>
                    <div class="timeSet2-right">
                        <div class="timeSet2-rightItem" v-for="(item,index) in stepThree.region.data" :key="index">
                            <span>{{item.name}}</span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" v-model.number="item.customers"></el-input></span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click"></el-input><span style="padding-top: 14px">%</span></span>
                            <span style="display: flex;align-items: center;padding-left: 24px"><el-input class="rightItemInput" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion"></el-input><span style="padding-top: 14px">%</span></span>
                        </div>
                    </div>
                </div>

                <div v-if="activeIndex === 2 || activeIndex === 3" :class="activeIndex===2?'form-title': 'form-title2'" style="margin-top: 40px">
                    <i class="iconfont">&#xe678;</i>人群定向
                </div>
<!--                人群定向-->
                <div class="crowdOrient" v-if="activeIndex===2">
                    <div class="time-title" style="width: 770px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix">
                        <div class="oneToSix-item1" v-for="(item,index) in stepTwo.crowd">
                            <span>{{index===0?'核心人群':index===1?'认知人群':index===2?'意向人群':index===3?'竞品人群':index===4?'店铺人群':'类目相关人群'}}</span>
                            <el-input class="item-input" @change="watchClient" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 160px;"></el-input>%
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click" style="margin-left: 86px;width: 160px;"></el-input>%</span>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion" style="margin-left: 70px;width: 160px;"></el-input>%</span>
                        </div>
                    </div>
                </div>

                <div class="crowdOrient" v-if="activeIndex===3" style="margin-left: 12px">
                    <div class="time-title" style="width: 750px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix">
                        <div class="oneToSix-item1" v-for="(item,index) in stepThree.crowd.data">
                            <span>{{item.name}}</span>
                            <el-input class="item-input" @change="watchClient" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 160px;"></el-input>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click" style="margin-left: 86px;width: 160px;"></el-input></span>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion" style="margin-left: 70px;width: 160px;"></el-input></span>
                        </div>
                    </div>
                </div>

                <div v-if="activeIndex === 2 || activeIndex === 3" :class="activeIndex===2?'form-title': 'form-title2'" style="margin-top: 40px">
                    <i class="iconfont">&#xe6e7;</i>资源位
                </div>
<!--                资源位-->
                <div class="resourcesNiche" v-if="activeIndex===2" style="margin-left: 14px">
                    <div class="time-title" style="width: 770px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix">
                        <div class="oneToSix-item1" v-for="(item,index) in stepTwo.resources">
                            <span>{{index===0?'搜索结果页底部通栏':index===1?'三级列表页底部通栏':index===2?'我的订单-底部通栏':index===3?'PC首页右侧小图':'PC首页焦点图'}}</span>
                            <el-input class="item-input" @change="watchClient2" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 160px;"></el-input>%
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click" style="margin-left: 86px;width: 160px;"></el-input>%</span>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion" style="margin-left: 70px;width: 160px;"></el-input>%</span>
                        </div>
                    </div>
                </div>

                <div class="resourcesNiche2" v-if="activeIndex===3" style="margin-left: 26px">
                    <div class="time-title" style="width: 770px;">
                        <span class="titleSpan">潜在客户数</span>
                        <span class="titleSpan">点击率</span>
                        <span class="titleSpan">转化率</span>
                    </div>
                    <div class="oneToSix">
                        <div class="oneToSix-item1" v-for="(item,index) in stepThree.resources.data">
                            <span>{{item.name}}</span>
                            <el-input class="item-input" @change="watchClient2" v-model.number="item.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="margin-left: 14px;width: 160px;"></el-input>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.click" style="margin-left: 86px;width: 160px;"></el-input></span>
                            <span><el-input class="item-input" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');if(this.value>100)this.value=100" v-model.number="item.conversion" style="margin-left: 70px;width: 160px;"></el-input></span>
                        </div>
                    </div>
                </div>

                <div class="stepFour" v-if="activeIndex===4">
                    <el-form-item label="竞争店铺总数" style="padding-left: 13px">
                        <el-input v-model="stores.customers" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="竞争店铺日限额">
                        <el-input v-model.number="stores.quota" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" style="width: 300px;"></el-input>
                    </el-form-item>
                    <el-form-item label="波动范围" style="padding-left: 42px">
                        <el-input-number :controls="false" :max="999" :min="-99" style="width: 60px;" v-model.number="stores.quotalower"></el-input-number> % ~
                        <el-input-number :controls="false" :max="1000" :min="-99" style="width: 60px;" @change="watchDiffer(stores.quotalower,stores.quotaupper)" v-model.number="stores.quotaupper"></el-input-number> %
                    </el-form-item>
                    <div class="form-title form-title3" style="margin-top: 40px">
                        <i class="iconfont">&#xe61f;</i>出价设置
                    </div>
                    <div class="bidSet">
                        <div class="bidSetTxt">
                            <span>竞争店铺出价</span>
                            <span>波动范围</span>
                        </div>
                        <div class="bidSetItem" v-for="(item,index) in stores.offer" :key="index">
                            <span>{{item.type == 1 ? '0-6' : item.type == 2 ? '6-12' : item.type == 3 ? '12-18' : '18-24'}}</span>
                            <span><el-input style="width: 160px;" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" v-model.number="item.offer"></el-input></span>
                            <span><el-input-number :controls="false" class="item-input" :max="999" :min="-99" v-model.number="item.offerlower" style="margin-right: 4px;width: 60px;"></el-input-number>% ~
                            <el-input-number @change="watchDiffer(item.offerlower,item.offerupper)" :controls="false" class="item-input" :max="1000" :min="-99" v-model.number="item.offerupper" style="margin-left: 6px;width: 60px;"></el-input-number>%</span>
                        </div>
                    </div>
                </div>

                <div class="creationSucceed" v-if="activeIndex===5">
                    <i class="iconfont">&#xe730;</i>
                    <span>创建成功!</span>
                </div>

            </el-form>
            <div class="footerBtn" v-if="activeIndex===1">
                <el-button class="btn" @click="toBack1">上一步</el-button>
                <el-button class="btn" @click="toNext1('form')">下一步</el-button>
            </div>
            <div class="footerBtn" v-if="activeIndex===2">
                <el-button class="btn" @click="comeback">上一步</el-button>
                <el-button class="btn" @click="toBack2">跳过设置</el-button>
                <el-button class="btn" @click="toNext2">生成数据</el-button>
            </div>
            <div class="footerBtn" v-if="activeIndex===3">
                <el-button class="btn" @click="toBack3">上一步</el-button>
                <el-button class="btn" @click="toNext3">下一步</el-button>
            </div>
            <div class="footerBtn" v-if="activeIndex===4">
                <el-button class="btn" @click="toBack4">上一步</el-button>
                <el-button class="btn" @click="toNext4">下一步</el-button>
            </div>
            <div class="footerBtn" style="padding-top: 320px" v-if="activeIndex===5">
                <el-button class="btn" @click="toNext5">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BoothAdvertisingTwo",
        data(){
            return {
                formOne:{
                    name:'',
                    industry_id:'',
                    residual:'',
                    score:void 0,
                    remake:'',
                    task_background: '',
                    tack_demands: '',
                    deal_score: void 0,
                    transform_score: void 0,
                },
                formFour:{
                    total:'',
                    dailyLimit:''
                },
                classifyOptions:[],
                rules:{
                    name:[
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 4,message: '最少4个长度', trigger: 'blur' }
                    ],
                    industry_id:[
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    score:[
                        { required: true, message: '请输入点击量分值', trigger: 'blur' },
                    ],
                    deal_score:[
                        { required: true, message: '请输入成交量分值', trigger: 'blur' },
                    ],
                    transform_score:[
                        { required: true, message: '请输入转化率分值', trigger: 'blur' },
                    ],
                    residual:[
                        { required: true, message: '请输入余额', trigger: 'blur' },
                    ],
                    task_background: [
                        { required: true, message: '请输入任务背景', trigger: 'blur' },
                        { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                    ],
                    tack_demands: [
                        { required: true, message: '请输入任务要求', trigger: 'blur' },
                        { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                    ],
                },
                activeIndex:1,
                SelectValue:'',
                officialOptions:[],
                stepTwo:{
                    time_interval:[ //时段设置
                        {
                            title:'潜在客户数',
                            type:1,
                            customers:null,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            title:'点击率',
                            type:2,
                            customers:null,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            title:'转化率',
                            type:3,
                            customers:null,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            title:'转化率',
                            type:4,
                            customers:null,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                    ],
                    region:[
                        {
                            code:1001,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1002,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1003,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1004,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1005,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1006,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                        {
                            code:1007,
                            click:null,
                            conversion:null,
                            cuslower:null,
                            cusupper:null,
                            clilower:null,
                            cliupper:null,
                            conlower:null,
                            conupper:null
                        },
                    ],
                    crowd:[
                        {
                            type:1,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:2,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:3,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:4,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:5,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:6,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                    ],
                    resources:[
                        {
                            type:1,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:2,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:3,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:4,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                        {
                            type:5,
                            customers:null,
                            click:null,
                            conversion:null
                        },
                    ],
                },
                stepThree:{
                    timeInterval:{
                        data:[
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "0-1",
                                "type": 0
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "1-2",
                                "type": 1
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "2-3",
                                "type": 2
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "3-4",
                                "type": 3
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "4-5",
                                "type": 4
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "5-6",
                                "type": 5
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "6-7",
                                "type": 6
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "7-8",
                                "type": 7
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "8-9",
                                "type": 8
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "9-10",
                                "type": 9
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "10-11",
                                "type": 10
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "11-12",
                                "type": 11
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "12-13",
                                "type": 12
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "13-14",
                                "type": 13
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "14-15",
                                "type": 14
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "15-16",
                                "type": 15
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "16-17",
                                "type": 16
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "17-18",
                                "type": 17
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "18-19",
                                "type": 18
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "19-20",
                                "type": 19
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "20-21",
                                "type": 20
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "21-22",
                                "type": 21
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "22-23",
                                "type": 22
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "23-24",
                                "type": 23
                            },
                        ],
                    },
                    region:{
                        data:[
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "北京市",
                                "code": 100101
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "天津市",
                                "code": 100102
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "河北省",
                                "code": 100103
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "山西省",
                                "code": 100104
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "内蒙古自治区",
                                "code": 100105
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "辽宁省",
                                "code": 100501
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "吉林省",
                                "code": 100502
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "黑龙江省",
                                "code": 100503
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "上海市",
                                "code": 100301
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "江苏省",
                                "code": 100302
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "浙江省",
                                "code": 100303
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "福建省",
                                "code": 100304
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "安徽省",
                                "code": 100305
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "山东省",
                                "code": 100306
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "河南省",
                                "code": 100201
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "湖北省",
                                "code": 100202
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "湖南省",
                                "code": 100203
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "江西省",
                                "code": 100204
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "广东省",
                                "code": 100401
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "海南省",
                                "code": 100402
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "广西壮族自治区",
                                "code": 100403
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "重庆市",
                                "code": 100701
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "四川省",
                                "code": 100702
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "云南省",
                                "code": 100703
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "贵州省",
                                "code": 100704
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "西藏自治区",
                                "code": 100705
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "陕西省",
                                "code": 100601
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "甘肃省",
                                "code": 100602
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "青海省",
                                "code": 100603
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "宁夏回族自治区",
                                "code": 100604
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "新疆维吾尔自治区",
                                "code": 100605
                            },
                        ]
                    },
                    crowd:{
                        data:[
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "核心人群",
                                "type": 1
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "认知人群",
                                "type": 2
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "意向人群",
                                "type": 3
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "竞品人群",
                                "type": 4
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "店铺人群",
                                "type": 5
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "类目相关人群",
                                "type": 6
                            },
                        ]
                    },
                    resources:{
                        data:[
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "搜索结果页底部通栏",
                                "type": 1
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "三级列表页底部通栏",
                                "type": 2
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "我的订单-底部通栏",
                                "type": 3
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "PC首页右侧小图",
                                "type": 4
                            },
                            {
                                "customers": null,
                                "click": null,
                                "conversion": null,
                                "name": "PC首页焦点图",
                                "type": 5
                            },
                        ]
                    }
                },
                stores:{
                    customers:null,
                    quota:null,
                    quotalower:0,
                    quotaupper:0,
                    offer:[
                        {
                            type:1,
                            offer:null,
                            offerlower:0,
                            offerupper:0,
                        },
                        {
                            type:2,
                            offer:null,
                            offerlower:0,
                            offerupper:0,
                        },
                        {
                            type:3,
                            offer:null,
                            offerlower:0,
                            offerupper:0,
                        },
                        {
                            type:4,
                            offer:null,
                            offerlower:0,
                            offerupper:0,
                        },
                    ]
                }
            }
        },
        created() {
            this.getCategoryList();
            this.getBoothList();
            this.getEditData();
        },
        methods: {
            comeback () {
                this.activeIndex = 1;
            },
            getEditData(){
                if(this.$route.query.id){
                    this.$http.axiosGetBy(this.$api.booth, {id:this.$route.query.id}, res=>{
                        if(res.code === 200){
                            let resData = res.data;
                            this.formOne.name = resData.name;
                            this.formOne.industry_id = resData.industry_id;
                            this.formOne.score = resData.score;
                            this.formOne.residual = resData.amount;
                            this.formOne.remake = resData.bz;
                            this.formOne.task_background = resData.task_background;
                            this.formOne.tack_demands = resData.tack_demands;
                            this.formOne.deal_score = resData.deal_score;
                            this.formOne.transform_score = resData.transform_score;
                            this.stepTwo.time_interval = resData.form.time_interval;
                            this.stepTwo.region = resData.form.region;
                            this.stepTwo.crowd = resData.form.crowd;
                            this.stepTwo.resources = resData.form.resources;
                            this.stepThree.timeInterval.data = resData.timeInterval.data;
                            this.stepThree.region.data = resData.region.data;
                            this.stepThree.crowd.data = resData.crowd.data;
                            this.stepThree.resources.data = resData.resources.data;
                            this.stores = resData.form.stores
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            selectOfficial(val){
                this.$http.axiosGetBy(this.$api.booth, {id:val}, res=>{
                    if(res.code === 200){
                        this.stepTwo.time_interval = res.data.form.time_interval;
                        this.stepTwo.region = res.data.form.region;
                        this.stepTwo.crowd = res.data.form.crowd;
                        this.stepTwo.resources = res.data.form.resources;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectOfficial2(val){
                this.$http.axiosGetBy(this.$api.booth, {id:val}, res=>{
                    if(res.code === 200){
                        this.stepThree.timeInterval.data = res.data.timeInterval.data.data;
                        this.stepThree.region.data = res.data.region.data.data;
                        this.stepThree.crowd.data = res.data.crowd.data.data;
                        this.stepThree.resources.data = res.data.resources.data.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectOfficial3(val){
                this.$http.axiosGetBy(this.$api.booth, {id:val}, res=>{
                    if(res.code === 200){
                        this.stores = res.data.form.stores
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getBoothList(){
                this.$http.axiosGet(this.$api.booth, res=>{
                    // console.log(1144,res)
                    if(res.code === 200){
                        this.officialOptions = res.data.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getCategoryList(){
                this.$http.axiosGet(this.$api.categoryList, res=>{
                    if(res.code === 200){
                        this.classifyOptions = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            watchDiffer(val,num){
                if(val>num){
                    this.$message.warning('波动范围最小值不能大于最大值！')
                }
            },
            watchClient(){
                let num = 0;
                let list = [];
                this.stepTwo.crowd.map(item=>{
                    if(item.customers){
                        list.push(item.customers);
                    }
                })
                if(list.length===6){
                    list.map(item=>{
                        num += item
                    })
                    if(num!=100){
                        this.$message.warning('潜在客户总百分比总共必须是100%!')
                    }
                }
            },
            watchClient2(){
                let num = 0;
                let list = [];
                this.stepTwo.resources.map(item=>{
                    if(item.customers){
                        list.push(item.customers)
                    }
                })
                if(list.length === 5){
                    list.map(item=>{
                        num += item
                    })
                    if(num!=100){
                        this.$message.warning('潜在客户总百分比总共必须是100%!')
                    }
                }

            },
            toBack2(){
                for(let i = 0; i < this.stepTwo.time_interval.length; i++){
                    if(!this.stepTwo.time_interval[i].customers && !this.stepTwo.time_interval[i].click && !this.stepTwo.time_interval[i].conversion && !this.stepTwo.time_interval[i].cuslower && !this.stepTwo.time_interval[i].cusupper && !this.stepTwo.time_interval[i].clilower && !this.stepTwo.time_interval[i].cliupper && !this.stepTwo.time_interval[i].conlower && !this.stepTwo.time_interval[i].conupper){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepTwo.region.length; i++){
                    if(!this.stepTwo.region[i].click && !this.stepTwo.region[i].conversion  && !this.stepTwo.region[i].cuslower && !this.stepTwo.region[i].cusupper && !this.stepTwo.region[i].clilower && !this.stepTwo.region[i].cliupper && !this.stepTwo.region[i].conlower && !this.stepTwo.region[i].conupper){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepTwo.crowd.length; i++){
                    if(!this.stepTwo.crowd[i].customers && !this.stepTwo.crowd[i].click && !this.stepTwo.crowd[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i =0; i < this.stepTwo.resources.length; i++){
                    if(!this.stepTwo.resources[i].customers && !this.stepTwo.resources[i].click && !this.stepTwo.resources[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                this.activeIndex = 3;
            },
            toNext2(){
                for(let i = 0; i < this.stepTwo.time_interval.length; i++){
                    if(!this.stepTwo.time_interval[i].customers && !this.stepTwo.time_interval[i].click && !this.stepTwo.time_interval[i].conversion && !this.stepTwo.time_interval[i].cuslower && !this.stepTwo.time_interval[i].cusupper && !this.stepTwo.time_interval[i].clilower && !this.stepTwo.time_interval[i].cliupper && !this.stepTwo.time_interval[i].conlower && !this.stepTwo.time_interval[i].conupper){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepTwo.region.length; i++){
                    if(!this.stepTwo.region[i].click && !this.stepTwo.region[i].conversion  && !this.stepTwo.region[i].cuslower && !this.stepTwo.region[i].cusupper && !this.stepTwo.region[i].clilower && !this.stepTwo.region[i].cliupper && !this.stepTwo.region[i].conlower && !this.stepTwo.region[i].conupper){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepTwo.crowd.length; i++){
                    if(!this.stepTwo.crowd[i].customers && !this.stepTwo.crowd[i].click && !this.stepTwo.crowd[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i =0; i < this.stepTwo.resources.length; i++){
                    if(!this.stepTwo.resources[i].customers && !this.stepTwo.resources[i].click && !this.stepTwo.resources[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                let time_interval = JSON.stringify(this.stepTwo.time_interval);
                let region = JSON.stringify(this.stepTwo.region);
                let crowd = JSON.stringify(this.stepTwo.crowd);
                let resources = JSON.stringify(this.stepTwo.resources);
                let formData = new FormData();
                formData.append('time_interval',time_interval);
                formData.append('region',region);
                formData.append('crowd',crowd);
                formData.append('resources',resources);
                this.$http.axiosPost(this.$api.data,formData,res=>{
                    if(res.code === 200){
                        this.stepThree = res.data;
                        this.activeIndex = 3;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toBack1(){
                this.$router.go(-1)
            },
            toNext1(formName){
              this.$refs[formName].validate((valid) => {
                if (valid) {
                  if (this.formOne.score + this.formOne.deal_score + this.formOne.transform_score !== 100) {
                    this.$message.warning('点击量分值、点击量分值、转化率分值的和应为100')
                    return
                  }
                  this.activeIndex = 2;
                } else {
                  console.log('error submit!!');
                  return false;
                }
              });
                // if(this.formOne.name && this.formOne.industry_id && this.formOne.score && this.formOne.residual && this.formOne.task_background && this.formOne.tack_demands){
                //     // if(this.$route.query.id){
                //     //     this.$http.axiosGetBy(this.$api.booth, {id:this.$route.query.id}, res=>{
                //     //         if(res.code === 200){
                //     //             let resData = res.data;
                //     //             console.log(res.data)
                //     //             this.stepTwo.time_interval = JSON.parse(resData.form.time_interval);
                //     //             return
                //     //             this.stepTwo.region = resData.form.region;
                //     //             this.stepTwo.crowd = resData.form.crowd;
                //     //             this.stepTwo.resources = resData.form.resources;
                //     //         } else {
                //     //             this.$message.warning(res.msg)
                //     //         }
                //     //     })
                //     // }
                //     this.activeIndex = 2;
                // } else {
                //     this.$message.warning('必填项不能为空！')
                // }
            },
            toBack3(){
                this.activeIndex = 2;
            },
            toNext3(){
                for(let i =0; i < this.stepThree.timeInterval.data.length; i++){
                    if(!this.stepThree.timeInterval.data[i].customers || !this.stepThree.timeInterval.data[i].click ||!this.stepThree.timeInterval.data[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepThree.region.data.length; i++){
                    if(!this.stepThree.region.data[i].customers || !this.stepThree.region.data[i].click ||!this.stepThree.region.data[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepThree.crowd.data.length; i++){
                    if(!this.stepThree.crowd.data[i].customers || !this.stepThree.crowd.data[i].click ||!this.stepThree.crowd.data[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                for(let i = 0; i < this.stepThree.resources.data.length; i++){
                    if(!this.stepThree.resources.data[i].customers || !this.stepThree.resources.data[i].click ||!this.stepThree.resources.data[i].conversion){
                        this.$message.warning('每项为必填！');
                        return;
                    }
                }
                this.activeIndex = 4
            },
            toBack4(){
                this.activeIndex = 3
            },
            toNext4(){
                if(this.stores.customers.length<0 && this.stores.quota.length<0 && this.stores.quotalower.length<0 && this.stores.quotaupper.length<0){
                    this.$message.warning('每项不能为空！');
                    return;
                } else {
                    for(let i = 0; i < this.stores.offer.length; i++){
                        if(this.stores.offer[i].offer.length<0 && this.stores.offer[i].offerlower.length<0 && this.stores.offer[i].offerupper.length<0){
                            this.$message.warning('每项不能为空！');
                            return;
                        }
                    }
                }
                let time_interval = JSON.stringify(this.stepTwo.time_interval);
                let region = JSON.stringify(this.stepTwo.region);
                let crowd = JSON.stringify(this.stepTwo.crowd);
                let resources = JSON.stringify(this.stepTwo.resources);
                let stores = JSON.stringify(this.stores);
                let time_interval_data = JSON.stringify(this.stepThree.timeInterval);
                let region_data = JSON.stringify(this.stepThree.region);
                let crowd_data = JSON.stringify(this.stepThree.crowd);
                let resources_data = JSON.stringify(this.stepThree.resources);
                let formData = new FormData();
                formData.append('name',this.formOne.name);
                formData.append('industry_id',this.formOne.industry_id);
                formData.append('bz',this.formOne.remake);
                formData.append('task_background',this.formOne.task_background);
                formData.append('tack_demands',this.formOne.tack_demands);
                formData.append('amount',this.formOne.residual);
                formData.append('score',this.formOne.score);
                formData.append('time_interval',time_interval);
                formData.append('region',region);
                formData.append('crowd',crowd);
                formData.append('resources',resources);
                formData.append('stores',stores);
                formData.append('time_interval_data',time_interval_data);
                formData.append('region_data',region_data);
                formData.append('crowd_data',crowd_data);
                formData.append('resources_data',resources_data);
                formData.append("deal_score", this.formOne.deal_score)
                formData.append("transform_score", this.formOne.transform_score)
                if(this.$route.query.id){
                    formData.append('id',this.$route.query.id)
                }
                this.$http.axiosPost(this.$api.booth, formData, res=>{
                    if(res.code === 200){
                        this.activeIndex = 5;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toNext5(){
                this.$router.push({
                    path:'/admin/operatedata/boothadvertising'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .BoothAdvertisingTwo{
        //height: 100%;
        .headerStep{
            margin-top: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            .stepOne{
                display: flex;
                flex-direction: column;
                align-items: center;
                .num{
                    line-height: 1;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #D1D2DB;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 40px;
                }
                .numActive{
                    background: #1122D8;
                }
                .text{
                    line-height: 1;
                    color: #878994;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 10px;
                }
            }
            .arrows{
                height: 10px;
                margin-bottom: 20px;
            }
        }
        .BoothAdvertisingTwoHeader{
            margin-top: 76px;
            ::v-deep .step-two-form{
                padding-left: 30px;
                .office-title {
                    margin-bottom: 40px;
                    .el-form-item__label {
                        font-size: 16px;
                        font-weight: 500;
                        color: #13131B;
                    }
                }
                .form-title {
                    margin-top: 20px;
                    color: #1121D7;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    padding-bottom: 12px;
                    position: relative;
                    font-weight: bold;
                    .iconfont {
                        margin-right: 10px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 26px;
                        bottom: 0;
                        width: 760px;
                        border-bottom: 1px solid #DBDBDB;
                    }
                }
                .form-title2{
                    margin-top: 20px;
                    color: #1121D7;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    padding-bottom: 12px;
                    position: relative;
                    font-weight: bold;
                    .iconfont {
                        margin-right: 10px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 26px;
                        bottom: 0;
                        width: calc(100% - 160px);
                        border-bottom: 1px solid #DBDBDB;
                    }
                }
                .form-title3{
                    margin-top: 20px;
                    color: #1121D7;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    padding-bottom: 12px;
                    position: relative;
                    font-weight: bold;
                    .iconfont {
                        margin-right: 10px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 26px;
                        bottom: 0;
                        width: calc(100% - 500px);
                        border-bottom: 1px solid #DBDBDB;
                    }
                }
                .form-content{
                    padding-left: 45px;
                    & > .el-form-item {
                        margin-top: 20px;
                        margin-right: 110px;
                        width: 120px;
                    }
                    &.goods-info-wrapper{
                        display: flex;
                        .item {
                            .line-title {
                                line-height: 41px;
                                width: 70px;
                                margin-bottom: 22px;
                                text-align: right;
                                position: relative;
                                margin-right: 10px;
                                &.line-title-first {
                                    width: 160px;
                                    line-height: 1;
                                    font-size: 16px;
                                    color: #13131B;
                                    height: 16px;
                                    text-align: center;
                                    margin: 30px 60px 20px 0;
                                }
                            }
                            &.item-line1 {
                                .line-title {
                                    &:before {
                                        content: '*';
                                        color: #F56C6C;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                .timeSet{
                    //width: 758px;
                  box-sizing: border-box;
                    padding-left: 56px;
                    .time-title{
                        color: #13131B;
                        font-size: 14px;
                        padding-top: 24px;
                        display: flex;
                        justify-content:space-around;
                        .titleSpan:nth-child(1){
                            margin-left: 16px;
                        }
                    }

                    .oneToSix{
                        margin-top: 18px;
                        .oneToSix-item1{
                            display: flex;
                            /*justify-content: space-between;*/
                            align-items: center;
                          ::v-deep .item-input{
                                width: 160px;
                            }
                            span:nth-child(1){
                                //padding-left: 32px;
                                width: 40px;
                                text-align: center;
                            }
                        }
                        .oneToSix-item2{
                            margin-top: 10px;
                            display: flex;
                            align-items: center;
                            /*justify-content: space-between;*/
                            .item2-input{
                              ::v-deep .item-input{
                                    width: 60px;
                                    /*display: flex;*/
                                }
                            }
                        }
                    }
                }
                .timeSet2{
                    display: flex;
                    padding-left: 36px;
                    width: calc(100% - 130px);
                    .timeSet2-left{
                        /*width: 72px;*/
                        padding-left: 10px;
                        padding-top: 60px;
                        display: flex;
                        flex-direction: column;
                        color: #14141C;
                        font-size: 14px;
                        span{
                            width: 70px;
                            display: inline-block;
                            text-align:right
                            //margin-left: -10px;
                        }
                        span:nth-child(1){
                            //margin-left: -2px;
                            padding-top: 10px;
                        }
                        span:nth-child(2){
                            padding-top: 42px;
                        }
                        span:nth-child(3){
                            padding-top: 42px;
                        }
                        span:nth-child(4){
                            //margin-left: -2px;
                            padding-top: 84px;
                        }
                        span:nth-child(5){
                            padding-top: 40px;
                        }
                        span:nth-child(6){
                            padding-top: 38px;
                        }
                        span:nth-child(7){
                            padding-top: 86px;
                        }
                        span:nth-child(8){
                            padding-top: 38px;
                        }
                        span:nth-child(9){
                            padding-top: 38px;
                        }
                        span:nth-child(10){
                            padding-top: 86px;
                        }
                        span:nth-child(11){
                            padding-top: 38px;
                        }
                        span:nth-child(12){
                            padding-top: 38px;
                        }
                        span:nth-child(13){
                            padding-top: 100px;
                        }
                        span:nth-child(14){
                            padding-top: 38px;
                        }
                        span:nth-child(15){
                            padding-top: 38px;
                        }
                        span:nth-child(16){
                            padding-top: 92px;
                        }
                        span:nth-child(17){
                            padding-top: 38px;
                        }
                        span:nth-child(18){
                            padding-top: 38px;
                        }
                    }
                    .timeSet2-right{
                        display: flex;
                        flex-wrap: wrap;
                        /*justify-content: space-around;*/
                        width: calc(100% - 70px);
                        padding-left: 0px;
                        .timeSet2-rightItem{
                            flex: 0 0 16.6%;
                            margin-top: 30px;
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            .rightItemInput{
                                width: 160px;
                                margin-top: 18px;
                            }
                        }
                    }
                }
                .regionSet{
                    //width: 758px;
                  box-sizing: border-box;
                  padding-left:40px;
                    .time-title{
                        color: #13131B;
                        font-size: 14px;
                        padding-top: 24px;
                        display: flex;
                        justify-content:space-around;
                        .titleSpan:nth-child(1){
                            margin-left: 16px;
                        }
                    }

                    .oneToSix{
                        margin-top: 18px;
                        .oneToSix-item1{
                            display: flex;
                            /*justify-content: space-between;*/
                            align-items: center;
                          ::v-deep .item-input{
                                width: 160px;
                            }
                            span:nth-child(1){
                                width: 56px;
                                display: inline-block;
                            }
                        }
                        .oneToSix-item2{
                            margin-top: 10px;
                            display: flex;
                            align-items: center;
                            /*justify-content: space-between;*/
                            .item2-input{
                              ::v-deep .item-input{
                                    width: 60px;
                                    /*display: flex;*/
                                }
                            }
                        }
                    }
                }
                .crowdOrient{
                  box-sizing: border-box;
                  padding-left: 54px;
                    .time-title{
                        color: #13131B;
                        font-size: 14px;
                        padding-top: 24px;
                        display: flex;
                        justify-content:space-around;
                        .titleSpan:nth-child(1){
                            margin-left: 16px;
                        }
                    }
                    .oneToSix{
                        margin-top: 18px;
                        .oneToSix-item1{
                            margin-top: 0;
                        }
                        .oneToSix-item1{
                            margin-top: 18px;
                            display: flex;
                            /*justify-content: space-between;*/
                            align-items: center;
                          ::v-deep .item-input{
                                width: 160px;
                            }
                            span:nth-child(1){
                                /*width: 56px;*/
                                display: inline-block;
                            }
                        }
                        .oneToSix-item1:nth-child(6){
                            span:nth-child(1){
                                /*width: 96px;*/
                                margin-left: -27px;
                            }
                        }
                        .oneToSix-item2{
                            margin-top: 10px;
                            display: flex;
                            align-items: center;
                            /*justify-content: space-between;*/
                            .item2-input{
                              ::v-deep .item-input{
                                    width: 60px;
                                    /*display: flex;*/
                                }
                            }
                        }
                    }
                }
                .resourcesNiche{
                  box-sizing: border-box;
                  padding-left: 30px;
                    .time-title{
                        color: #13131B;
                        font-size: 14px;
                        padding-top: 24px;
                        display: flex;
                        justify-content:space-around;
                        .titleSpan:nth-child(1){
                            margin-left: 36px;
                        }
                        .titleSpan:nth-child(2){
                            margin-left: 30px;
                        }
                        .titleSpan:nth-child(3){
                            margin-left: 20px;
                        }
                    }

                    .oneToSix{
                        margin-top: 18px;
                        .oneToSix-item1:nth-child(1){
                            margin-top: 0px;
                            span:nth-child(1){
                                margin-left: -60px;
                            }
                        }
                        .oneToSix-item1:nth-child(2){
                            span:nth-child(1){
                                margin-left: -60px;
                            }
                        }
                        .oneToSix-item1:nth-child(3){
                            span:nth-child(1){
                                margin-left: -51px;
                            }
                        }
                        .oneToSix-item1:nth-child(4){
                            span:nth-child(1){
                                margin-left: -36px;
                            }
                        }
                        .oneToSix-item1:nth-child(5){
                            span:nth-child(1){
                                margin-left: -22px;
                            }
                        }
                        .oneToSix-item1{
                            margin-top: 18px;
                            display: flex;
                            /*justify-content: space-between;*/
                            align-items: center;
                          ::v-deep .item-input{
                                width: 160px;
                            }
                            span:nth-child(1){
                                display: inline-block;
                                /*width: 110px;*/
                            }
                        }
                        .oneToSix-item2{
                            margin-top: 10px;
                            display: flex;
                            align-items: center;
                            /*justify-content: space-between;*/
                            .item2-input{
                              ::v-deep .item-input{
                                    width: 60px;
                                    /*display: flex;*/
                                }
                            }
                        }
                    }
                }
                .resourcesNiche2{
                    width: 758px;
                    padding-left: 47px;
                    .time-title{
                        color: #13131B;
                        font-size: 14px;
                        padding-top: 24px;
                        display: flex;
                        justify-content:space-around;
                        .titleSpan:nth-child(1){
                            margin-left: 10px;
                        }
                        .titleSpan:nth-child(2){
                            margin-right: 10px;
                        }
                        .titleSpan:nth-child(3){
                            margin-right: 28px;
                        }
                    }

                    .oneToSix{
                        margin-top: 18px;
                        .oneToSix-item1:nth-child(1){
                            margin-top: 0px;
                            span:nth-child(1){
                                margin-left: -28px;
                            }
                        }
                        .oneToSix-item1:nth-child(2){
                            span:nth-child(1){
                                margin-left: -30px;
                            }
                        }
                        .oneToSix-item1:nth-child(3){
                            span:nth-child(1){
                                margin-left: -42px;
                            }
                        }
                        .oneToSix-item1:nth-child(4){
                            span:nth-child(1){
                                margin-left: -60px;
                            }
                        }
                        .oneToSix-item1:nth-child(5){
                            span:nth-child(1){
                                margin-left: -46px;
                            }
                        }
                        .oneToSix-item1{
                            margin-top: 18px;
                            display: flex;
                            /*justify-content: space-between;*/
                            align-items: center;
                          ::v-deep .item-input{
                                width: 160px;
                            }
                            span:nth-child(1){
                                display: inline-block;
                            }
                        }
                        .oneToSix-item2{
                            margin-top: 10px;
                            display: flex;
                            align-items: center;
                            /*justify-content: space-between;*/
                            .item2-input{
                              ::v-deep .item-input{
                                    width: 60px;
                                    /*display: flex;*/
                                }
                            }
                        }
                    }
                }
                .stepFour{
                    .bidSet{
                        padding-left: 24px;
                        display: flex;
                        .bidSetTxt{
                            padding-left: 8px;
                            text-align: right;
                            display: flex;
                            flex-direction: column;
                            padding-top: 72px;
                            span:nth-child(2){
                                padding-top: 34px;
                            }
                            margin-right: 10px;
                        }
                        .bidSetItem:nth-child(2){
                            margin-left: 0;
                        }
                        .bidSetItem{
                            margin-left: 80px;
                            display: flex;
                            flex-direction: column;
                            span:nth-child(1){
                                text-align: center;
                                padding-top: 28px;
                            }
                            span:nth-child(2){
                                padding-top: 20px;
                            }
                            span:nth-child(3){
                                padding-top: 10px;
                            }
                        }
                    }
                }
                .creationSucceed{
                    margin-top: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    i{
                        font-size: 52px;
                        color: #20D08C;
                    }
                    span{
                        color: #14141C;
                        font-size: 24px;
                    }
                }
            }
            .footerBtn{
                display: flex;
                justify-content: center;
                padding-top: 136px;
                padding-bottom: 70px;
                .btn{
                    width: 100px;
                    height: 30px;
                    line-height: 0;
                    border-color: #1122D8;
                }
                .btn:nth-child(1){
                    background: #ffffff;
                    color: #1122D8;
                }
                .btn:nth-child(2){
                    background: #1122D8;
                    color: #fff;
                }
            }
        }
    }
</style>